<template>
  <div v-if="loading" class="report-list-loading">
    <van-loading size="50px" color="#00c5cd" />
  </div>

  <div class="report-list-container" v-if="!loading && reports.data.length > 0">
    <!-- <div class="title">近期健康趋势</div> -->
    <div class="title"></div>
    <div class="trend">
      <Trend :data="history" :ignoreTitle="true" />
    </div>
    <van-list class="list">
      <div
        class="item"
        v-for="item in reports.data"
        v-bind:key="item.id"
        @click="onGoTo(item.id)"
      >
        健康状态
        <span :class="classNames(item.score)">{{ item.result_text }}</span>
        <span class="time">{{ item.create_at }}</span>
      </div>
    </van-list>
    <!-- <div class="operator">
      <Button type="primary" @click="onStart">开始评估</Button>
    </div> -->
  </div>
  <div
    class="report-empty-container"
    v-if="!loading && reports.data.length === 0"
  >
    <!-- <div class="title">近期健康趋势</div> -->
    <div class="title"></div>
    <div class="empty">
      <div class="img">
        <img src="/assets/error/empty.png" alt="" />
      </div>
      <div class="tips">您还没有进行过检测哦 ~</div>
    </div>
  </div>
</template>
<script>
import { getUserByCode, getHistory, getReportList } from "@/services/api";
import Trend from "../components/report/trend";
import { LOGIN_URL } from "../meta/variables";

export default {
  data() {
    return {
      history: [],
      reports: { data: [] },
      loading: false,
      useid: "",
    };
  },
  components: { Trend },
  watch: {
    $route() {
      this.useid = this.$route.query.user_id;
      this.getMessage(this.$route.query.user_id);
    },
  },
  mounted() {
    if (this.$route.query.error) {
      this.$router.push({
        path: "/error",
        query: { error: this.$route.query.error },
      });
      return;
    }
    if (this.$route.query.code) {
      // 已获取到code
      this.loading = true;
      getUserByCode(this.$route.query.code).then((res) => {
        if (res.code === 0) {
          this.$router.replace({
            path: "/list",
            query: { user_id: res.data },
          });
        }
      });
      return;
    }

    if (!this.$route.query.user_id) {
      window.location.href = `${LOGIN_URL}?page=2`;
    } else {
      this.useid = this.$route.query.user_id;
      this.getMessage(this.$route.query.user_id);
    }
  },
  methods: {
    classNames: function (score) {
      if (+score > 80) {
        return "result success";
      } else if (+score > 40) {
        return "result warning";
      } else {
        return "result danger";
      }
    },
    getMessage: function (value) {
      getHistory(value).then((data) => {
        if (data.code === 0) {
          this.history = data.data.list;
        }
      });
      getReportList(value, 1)
        .then((data) => {
          if (data.code === 0) {
            this.reports = data.data;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onStart: function () {
      this.$router.push({
        path: "/face",
        // order_code, user_id, user_phone
        query: { ...this.$route.query, user_id: this.useid },
      });
    },
    onGoTo: function (id) {
      this.$router.push({
        path: "/report",
        // order_code, user_id, user_phone
        query: { ...this.$route.query, user_id: this.useid, id: id },
      });
    },
  },
};
</script>
