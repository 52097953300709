<template>
  <Title v-if="!ignoreTitle" title="健康趋势" subTitle="My Trend" />
  <div class="report-trend-container">
    <div id="chart"></div>
  </div>
</template>
<script>
import Title from "./title";
import { Area } from "@antv/g2plot";

export default {
  data() {
    return {
      chart: null,
    };
  },
  components: { Title },
  props: ["data", "ignoreTitle"],
  mounted() {
    this.renderChart();
  },
  unmounted() {
    this.chart.destroy();
  },
  methods: {
    renderChart() {
      const options = {
        data: this.data.map((item, index) => ({
          score: +item.score,
          time: `T${index + 1}`,
        })),
        xField: "time",
        yField: "score",
        xAxis: {
          grid: {
            visible: true,
          },
        },
        yAxis: {
          line: {
            visible: true,
          },
          tickLine: {
            visible: true,
          },
          min: 0,
          max: 100,
        },
        line: {
          visible: false,
        },
        point: {
          visible: true,
          style: {
            stroke: "#00C5CD",
            fill: "#00C5CD",
          },
        },
        areaStyle: {
          fill: "#00C5CD",
          fillOpacity: "0.2",
        },
      };
      if (!this.chart) {
        this.chart = new Area("chart", options);
      } else {
        this.chart.updateConfig(options);
      }
      this.chart.render();
    },
  },
  watch: {
    data: function(newVal, oldVal) {
      this.renderChart();
    },
  },
};
</script>
